.preloader-container {
  height: auto;
  left: 50%;
  max-width: 120px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  svg {
    .st0 {
      fill: #FFB300;
    }

    .st1 {
      fill: #FB7D28;
    }

    .st2 {
      fill: #CC3300;
    }
  }
}
