.footer {
  .euronet-link {
    text-align: center;

    a {
      align-items: center;
      display: flex;
      justify-content: center;

      svg {
        margin-left: 15px;
      }
    }
  }
  .footer-navigation {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1500px;
    padding: $spacing-md;
    width: 90%;

    @media screen and (max-width: 540px) {
      flex-direction: column;

      .button-home .icon-border {
        margin: 0;
      }
    }

    @media screen and (min-width: $tabletViewportWidth) {
      padding: 120px 0 $spacing-xl 0;
    }

    .ci-button {
      transition: opacity 250ms ease;

      &:not(:last-of-type) {
        @media screen and (max-width: 540px) {
          margin-bottom: 25px;
        }
      }

      &.icon- {
        @media screen and (max-width: 540px) {
          flex-direction: row-reverse;
          justify-content: flex-end;
        }

        .icon-border {
          @media screen and (max-width: 540px) {
            margin-left: 0;
            margin-right: 15px;
          }
        }
      }
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.footer-next-category {
  display: flex;
  height: 80vh;
  overflow: hidden;

  @media screen and (max-width: 540px) {
    flex-direction: column;
  }

  .article-image,
  .article-information {
    cursor: pointer;
    display: flex;
    height: auto;
    width: 50%;

    @media screen and (max-width: 540px) {
      height: 100%;
      width: 100%;
    }

    .big-headline-wrapper {
       max-width: 350px;
    }
  }

  .article-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 20px;
    transition: all 200ms ease-in-out;

    @media screen and (max-width: $tabletViewportWidth) and (max-height: $tabletLandscapeViewportWidth) {
      justify-content: flex-end;
      padding: 80px 20px;
    }

    @media screen and (min-width: $tabletViewportWidth) {
      padding: 120px 120px 60px 30px;
    }

    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      padding: 120px 260px 60px 100px;
    }

    .big-headline-wrapper {
      flex-wrap: wrap;
      max-width: 77%;

      @media screen and (min-width: $desktopViewportWidth) {
        max-width: 55%;
      }

      .big-headline {
        @include ci-font((font-weight: $font-base-weight-extra-thin, text-transform: uppercase, line-height: 1.2em, size-xs: 3.625rem, size-md: 80px, size-lg: 100px));

        @media screen and (min-width: $tabletLandscapeViewportWidth) and (max-height: 700px) {
          font-size: 80px;
        }
      }
    }
  }

  .article-image {
    transition: all 200ms ease-in-out;

    a {
      display: block;
      height: 100%;
      width: 100%;
    }

    .img-holder {
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%;
    }
  }


  &:hover {
    .article-information {
      width: 55%;
    }

    .article-image {
      opacity: 0.8;
    }
  }
}
