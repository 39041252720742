.clock-display {
  display: flex;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: 15;

  span {
    display: flex;
    width: 100%;
  }

  ul.clock-display__digits {
    height: auto;
    list-style-type:none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%;

    li {
      @include ci-font((font-weight: $font-base-weight-regular));
      color: white;
      font-size: 7.3vw;
      line-height: 1;
    }
  }
}
