.text-module {
  margin-bottom: $spacing-lg;

  @media screen and (min-width: $tabletViewportWidth) {
    margin-bottom: $spacing-xxl;
  }

  &.full-width {
    width: 100%;

    .text-media-container {

      @media screen and (min-width: $tabletViewportWidth) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  &.half-width {
    width: 100%;

    @media screen and (min-width: $tabletViewportWidth) {
      width: 48%;
    }
  }

  .text-media-headline {
    margin-bottom: $spacing-xs;

    @media screen and (min-width: $tabletViewportWidth) {
      margin-bottom: $spacing-md;
    }
  }
}

.text-description {
  @include ci-font((font-weight: $font-base-weight-light, line-height: 1.45em, size-xs: 18px, size-md: 20px, size-lg: 22px));
}

.list-item {
  list-style-position: outside;
  list-style: disc;
  margin-bottom: $spacing-sm;
  margin-left: $spacing-md;
}
