// general styles form fields
.contact-form {
  fieldset {
    @include ci-font((font-weight: $font-base-weight-regular, size-sm: 16px));
    height: 44px;
    margin-bottom: 25px;
    position: relative;

    &:last-of-type {
      min-height: 100px;
    }

    .label {
      @include ci-font((font-weight: $font-base-weight-regular, size-sm: 16px));
      cursor: pointer;
      font-size: 14px;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;

      &:before {
        @include pseudo;
        bottom: 0;
        height: 2px;
        transition: width 250ms ease-in-out;
        width: 0;
      }

      span {
        color: $black;
        left: 5px;
        position: absolute;
        top: 5px;
        transition: all 250ms;
        z-index: 5;
      }
    }

    input[type="text"],
    textarea {
      @include ci-font((font-weight: $font-base-weight-regular, size-sm: 16px));
      background-color: transparent;
      border-bottom: 2px solid #ddd;
      height: 100% !important;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      &:focus, &:valid {
        ~ .label {

          &:before {
            background-color: $black;
            width: 100%;
          }

          span {
            @include ci-font((font-weight: $font-base-weight-regular, size-sm: 11px));
            color: $black;
            left: 0;
            top: -15px;
          }
        }
      }
    }
  }

  button {
    @include ci-font((font-weight: $font-base-weight-medium, size-sm: 18px));
    background-color: #222;
    color: $white;
    cursor: pointer;
    padding: $spacing-xs $spacing-lg;
    position: relative;
    text-transform: uppercase;
    z-index: 1;

    &:before {
      @include pseudo;
      background-color: #444;
      height: 100%;
      left: 0;
      transition: width 250ms ease-in-out;
      top: 0;
      width: 0;
      z-index: -1;
    }

    &:hover {
      color: $white;

      &:before {
        width: 100%;
      }
    }
  }
}

.contact {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.form-fieldset-headline {
  @include ci-font((font-weight: $font-base-weight-regular, size-sm: 16px));
  color: $black;
  margin-bottom: $spacing-sm;
  text-transform: inherit;
}

.form-fieldset {
  display: flex;
  flex-wrap: wrap;

  span {
    position: relative;
    top: 4px;
  }
}

// styling for radio and checkbox
.form-checkbox-label,
.form-radio-label {
  cursor: pointer;
  display: flex;
  margin-bottom: $spacing-sm;
  padding-left: $spacing-sm * 2;
  position: relative;

  &:hover {
    .form-radio-button,
    .form-checkbox-button {
      color: #444;
    }
  }

  input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
  }
}

.radio-buttons,
.checkbox-buttons {
  height: auto !important;
  margin-bottom: 30px;
  min-height: 60px;
}

// radio ONLY
.form-radio-label {
  margin-right: $spacing-md;
}

.form-radio-field:checked {
  ~ .form-radio-button {
    &:before {color: $black;}

    &:after {transform: scale(0.5);}
  }
}

.form-radio-button {
  color: $grey;
  cursor: pointer;
  display: block;
  left: 0;
  position: absolute;
  user-select: none;

  &:before, &:after {
    @include pseudo;
    border-radius: 50%;
    border: 0.125rem solid currentColor;
    height: 1rem;
    left: 0;
    margin: 0.25rem;
    top: 0;
    transition: transform 0.28s ease, color 0.28s ease;
    width: 1rem;
    will-change: transform, color;
  }

  &:after {
    background-color: $black;
    border-color: $black;
    transform: scale(0);
  }
}

// checkbox ONLY
.checkbox-column {
  margin-right: $spacing-xl;

  span {
    top: 0;
  }
}

.form-checkbox-field:checked {
  ~ .form-checkbox-button {
    color: $black;

    &:before {
      height: 20px;
      opacity: 1;
      transition: height 0.28s ease .28s;
    }

    &:after {
      height: 10px;
      opacity: 1;
      transition: height 0.28s ease;
    }
  }
}

.form-checkbox-button {
  border-radius: 0.0625rem;
  border: 0.125rem solid currentColor;
  color: $grey;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  transition: color 0.28s ease;
  user-select: none;
  width: 20px;
  will-change: color;

  // check icon
  &:before, &:after {
    @include pseudo;
    background-color: $black;
    border-radius: 0.25rem;
    height: 0;
    opacity: 0;
    transform-origin: left top;
    transition: opacity 0.28s ease, height 0s linear 0.28s;
    width: 0.2rem;
    will-change: opacity, height;
  }

  &:before {
    box-shadow: 0 0 0 0.0625rem $white;
    left: 7px;
    top: 12px;
    transform: rotate(-135deg);
  }

  &:after {
    left: 0;
    top: 0.3rem;
    transform: rotate(-45deg);
  }
}

.contact-radio-checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: $tabletViewportWidth) {
    flex-direction: row;
  }
}

.contact-form-adress {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-lg;

  h5 {
    color: $black;
    margin-bottom: $spacing-sm;
  }

  span {
    line-height: 20px;
  }

  .contact-form-adress-contact {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
}
