.page-transition {
  background: $white;
  height: 200vh;
  left: 100%;
  opacity: 1;
  position: fixed;
  top: -50%;
  width: 100vw;
  z-index: 999;
}
