//$body-background-color: #f7f8fa;
$body-background-color: transparent;

// category colors
$color-info-center: #96bbc9;
$color-magazine: #d4a977;
$color-registrierkasse: #c45945;
$color-no-4: #dae9d7;
$color-no-5: #c2d4d8;
$color-no-6: #b0abc3;
$color-no-7: #466675;
$color-no-8: #583843;
$color-no-9: #97DBED;
$color-no-10: #f2db5b;
$color-no-11: #6cac8a;
$color-no-12: #874c7f;

$white: #fff;
$black: #333;
$grey: #999;

$link-color-secondary: #b3b3b3;

// breakpoints
///* ========================================================================
//   #Responsive breakpoints
//
//   | name |     scope       |
//   |------------------------|
//   |  xs  |    0px - 413px  |
//   |  sm  |  414px - 767px  |
//   |  md  |  768px - 1023px |
//   |  lg  | 1024px - 1259px |
//   |  xl  | 1260px - ∞      |
//
//   ======================================================================== */

$xs: 0;
$xs-max: 25.8125em;
$sm: 25.875em;
$sm-max: 47.9375em;
$md: 48em;
$md-max: 63.9375em;
$lg: 1024px;
$lg-max: 78.6875em;
$xl: 78.75em;
$xxl: 1300px;

// add variables for plugins which require responsive theme
$phoneLandscapeViewportWidth: $sm; // 480px
$tabletViewportWidth: $md; // 768px
$tabletLandscapeViewportWidth: $lg; // 1024px
$desktopViewportWidth: $xl; // 1260px
$desktopLargeViewportWidth: $xxl; // 1520px

// spacings
$spacing-xs: 10px;
$spacing-sm: 15px;
$spacing-md: 25px;
$spacing-lg: 40px;
$spacing-xl: 60px;
$spacing-xxl: 100px;

