.app-euronet {
  .euronet-logo {
    fill: $white;
  }

  .about-us {
    .ci-link-text {
      color: $white;
    }
  }

  &.is-subpage {
    .euronet-logo {
      fill: $black;
    }

    .about-us {
      border-color: $black;

      .ci-link-text {
        color: $black;
      }
    }

    .absolute {
      position: fixed;
    }

    .link-style {
      .ci-link-text {
        color: $black;

        &:hover::after {
          background-color: $black;
        }
      }
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 999;

  @media screen and (min-width: $tabletViewportWidth) {
    flex-direction: row;
  }
  @media screen and (min-width: $xl) {
    flex-direction: row;
    padding: 30px;
  }

  a {
    display: block;
    position: relative;
  }

  .euronet-logo {
    height: 45px;
    overflow: visible;
    width: auto;

    @media screen and (max-width: $tabletViewportWidth) {
      height: 30px;
    }
  }

  .about-us {
    align-items: center;
    border-radius: 50px;
    border: 1px solid $white;
    color: $white;
    cursor: pointer;
    display: flex;
    height: auto;
    justify-content: center;
    min-height: 0;
    padding: 0 $spacing-md;
    text-transform: uppercase;
    transition: background-color ease 250ms;

    span {
      padding-top: 3px;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
}
