.clock-container {
  display: none;
  left: 50%;
  max-width: 30vw;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 5;

  @media screen and (min-width: $tabletViewportWidth) {
    display: block;
  }

  @media screen and (max-width: $tabletViewportWidth) and (max-height: $tabletLandscapeViewportWidth) {
    max-width: 50vw;
    top: 20%;
    transform: translate(-50%, 0);
  }
}

svg.clock {
  .timepoint__hover {
    cursor: pointer;
    fill: #fff;
    opacity: 0;
    stroke-miterlimit: 10;
    stroke: #000;
  }

  .timepoint {
    cursor: pointer;
    fill: rgba(255, 255, 255, 0.8);
    transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

    &.active {
      fill: rgba(255, 255, 255, 1);
    }
  }

  .timepoint__hover:hover + .timepoint:not(.active), .timepoint:not(.active):hover {
    fill: rgba(255, 255, 255, 1);
  }

  .progress {
    /* clip-path: url(#clipPath); buggy with firefox! */
    fill: none;
    stroke-width: 500;
    stroke: rgba(255, 255, 255, 0.3);
  }

  .logo path {
    fill: #fff;
  }
}
