.text-media-module {
  margin-bottom: $spacing-lg;

  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    margin-bottom: $spacing-xxl;
  }

  .text-media-container img {
    margin-bottom: $spacing-sm;

    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      margin-bottom: $spacing-md;
    }
  }
}

.text-media-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: $tabletViewportWidth) {
    flex-direction: row;
    justify-content: space-between;
  }

  .text-media-description {
    @include ci-font((font-weight: $font-base-weight-light, line-height: 1.45em, size-xs: 18px, size-md: 20px)); //laut psd 24px

    @media screen and (min-width: $tabletViewportWidth) {
      padding-left: $spacing-lg;
    }
  }

  &.image-right {
    @media screen and (min-width: $tabletViewportWidth) {
      flex-direction: row-reverse;
    }

    .text-media-description {
      padding-left: 0;

      @media screen and (min-width: $tabletViewportWidth) {
        padding-right: $spacing-lg;
      }
    }
  }

  .w-50 {
    flex-direction: column;

    @media screen and (min-width: $tabletViewportWidth) {
      max-width: 50%;
    }
  }

  .w-66 {
    flex-direction: column;

    @media screen and (min-width: $tabletViewportWidth) {
      align-self: center;
      height: 100%;
      max-width: 46%;
    }

    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      max-width: 66%;
    }
  }
}

.text-media-headline {
  @include ci-font((font-weight: $font-base-weight-medium, line-height: 1.2em, size-xs: 20px, size-s: 24px, size-md: 40px));
  color: $font-color-base;
  margin-bottom: $spacing-sm;

  @media screen and (min-width: $phoneLandscapeViewportWidth) {
    margin-bottom: $spacing-md;
  }

  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    margin-bottom: $spacing-lg;
  }
}


