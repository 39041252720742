.article-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  opacity: 0;
  position: absolute;
  width: 100vw;

  @media screen and (max-width: 767px) {
    flex-direction: column-reverse;
    opacity: 1;
    position: relative;
  }

  &.active {
    opacity: 1;
  }

  &.odd {
    flex-direction: row-reverse;

    @media screen and (max-width: 767px) {
      flex-direction: column-reverse;
    }
  }

  .article-information,
  .article-image {
    height: 100%;
    overflow: hidden;
    width: 50%;
    z-index: 1;

    .img-holder {
      background-position: center;
      background-size: cover;
      display: block;
      height: 100%;
      width: 100%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .article-information {
    background-color: #96bbc9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    @media screen and (min-width: $tabletViewportWidth) {
      padding: 120px 14vw 60px 7vw;
    }

    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      padding: 120px 260px 60px 100px;
    }

    @media screen and (device-aspect-ratio: 2/3) {
      justify-content: flex-end;
      padding: 80px;
    }

    .big-headline-wrapper {
      flex-wrap: wrap;
      max-width: 100%;

      @media screen and (min-width: $tabletViewportWidth) {
        max-width: 77%;
      }
      @media screen and (min-width: $desktopViewportWidth) {
        max-width: 55%;
      }
      @media screen and (device-aspect-ratio: 2/3) {
        max-width: 100%;
      }

      .big-headline {
        @include ci-font((font-weight: $font-base-weight-extra-thin, text-transform: uppercase, line-height: 1.2em, size-xs: 40px, size-md: 80px, size-lg: 95px));

        @media screen and (max-width: $tabletViewportWidth) {
          margin-bottom: 10px;
        }

        @media screen and (min-width: $tabletLandscapeViewportWidth) and (max-height: 700px) {
          font-size: 80px;
        }
      }
    }

    .article-information-headline {
      @include ci-font((font-weight: $font-base-weight-medium, size-xs: 18px, size-md: 22px));
    }

    .article-subheadline {
      @include ci-font((font-weight: $font-base-weight-light, letter-spacing: .05em, size-xs: 18px, size-lg: 22px));
      margin-bottom: $spacing-sm;
    }

    .additional-information {
      flex-direction: column;
      z-index: 5;
    }
  }

  &.odd .article-information {
    @media screen and (max-width: $tabletViewportWidth) and (max-height: $tabletLandscapeViewportWidth) {
      padding: 80px 40px;
    }

    @media screen and (min-width: 769px) {
      padding: 120px 30px 60px 140px;
    }

    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      padding: 120px 7vw 60px 17vw;
    }


    @media screen and (device-aspect-ratio: 2/3) {
      padding: 80px;
    }
  }

  .article-image {
    cursor: pointer;
  }
}

.mobile-datatime {
  display: none;
}

@media screen and (max-width: $tabletViewportWidth) {
  .mobile-datatime {
    display: block;
    margin-bottom: $spacing-xs;
  }
}



