.categorie-subpage {
  .header {
    position: fixed;
    z-index: 2;

    .about-us {
      border: 2px solid $black;
      color: $black;
      z-index: 2;
    }
  }
}

h1, h3, .claim {
  text-transform: uppercase;
}

.category__content {
  flex-direction: column;
  display: flex;
  opacity: 1;

  .category__content--section {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    width: 100%;
    max-width: 1500px;
    padding: 40px $spacing-md $spacing-md $spacing-md;
    position: relative;
    z-index: 4;

    @media screen and (min-width: $tabletViewportWidth) {
      flex-direction: row;
      padding: 120px $spacing-xl $spacing-xl $spacing-xl;
    }
  }

  .fullwidth-image {
    margin-bottom: $spacing-lg;

    @media screen and (min-width: $desktopLargeViewportWidth) {
      margin-bottom: $spacing-xxl;
    }
  }
}

.additional-information {
  width: 100%;
}

.headline-claim-subpage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 50px;

  .intro {
    margin-right: 40px;
  }

  @media screen and (min-width: 540px) {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
  }

  @media screen and (min-width: $tabletViewportWidth) {
    justify-content: start;
    flex-direction: column;
    padding-left: $spacing-xl;
    width: 50%;
  }

  @media screen and (max-width: $tabletViewportWidth) {
    margin-top: 20px;
  }

  .claim {
    @include ci-font((font-weight: $font-base-weight-medium, size-lg: 22px));
  }

  h3 {
    @include ci-font((font-weight: $font-base-weight-regular, size-lg: 22px));
    color: $black;
    margin-bottom: $spacing-lg;
  }

  .ci-button {
    transition: opacity 250ms ease;

    &:hover {
      opacity: 0.8;
    }
  }

  .ci-link-text {
    @include ci-font((font-weight: $font-base-weight-medium, text-transform: uppercase));
    position: relative;
    padding: 0;
  }
}

.headline-subpage {
  @include ci-font((font-weight: $font-base-weight-regular, size-xs: 22px, size-md: 42px));
  color: $black;
  margin-bottom: $spacing-sm;

  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    font-weight: $font-base-weight-light;
    margin-bottom: $spacing-lg;
  }

  @media screen and (min-width: $desktopViewportWidth) {
    margin-bottom: $spacing-xl;
  }
}

.subpage-description {
  @include ci-font((font-weight: $font-base-weight-light, size-xs: 18px, size-md: 22px));
  margin-bottom: $spacing-md;

  @media screen and (min-width: $tabletViewportWidth) {
    margin-bottom: 0;
  }
}

//Parallax images
.parallax-background {
  margin-bottom: 40px;
  position: relative;
  padding: 270px 0 200px 0;
  overflow: hidden;
  width: 100%;

  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: $tabletLandscapeViewportWidth) {
    background-size: auto 100%;
    background-attachment: scroll;
  }


  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    padding-top: 380px;
  }
}

.section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1500px;
  padding: $spacing-md;
  width: 100%;

  @media screen and (min-width: $phoneLandscapeViewportWidth) {
    padding: $spacing-xxl $spacing-lg $spacing-lg $spacing-lg;
  }

  @media screen and (min-width: $tabletViewportWidth) {
    padding: 120px $spacing-xl $spacing-xl $spacing-xl;
  }

  .half-width-images {
    margin-bottom: $spacing-md;
    width: 100%;

    @media screen and (min-width: $phoneLandscapeViewportWidth) {
      margin-bottom: $spacing-lg;
    }

    @media screen and (min-width: $tabletViewportWidth) {
      margin-bottom: $spacing-xxl;
      width: 48%;
    }
  }
}
