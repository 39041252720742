// font mixin
@mixin ci-font($font: (font: $ci-font-base)) {

  $fontFamily: $ci-font-base;
  @if (map-has-key($font, "font")) {
    $fontFamily: map-get($font, "font");
  }

  font-family: $fontFamily;
  font-weight: normal;

  @if (map-has-key($font, "size-xs")) {
    font-size: map-get($font, "size-xs");
  }
  @if (map-has-key($font, "letter-spacing")) {
    letter-spacing: map-get($font, "letter-spacing");
  }
  @if (map-has-key($font, "text-transform")) {
    text-transform: map-get($font, "text-transform");
  }
  @if (map-has-key($font, "line-height")) {
    line-height: map-get($font, "line-height");
  }
  @if (map-has-key($font, "font-weight")) {
    font-weight: map-get($font, "font-weight");
  }

  @if (map-has-key($font, "size-sm")) {
    @media (min-width: $phoneLandscapeViewportWidth) {
      font-size: map-get($font, "size-sm");
    }
  }

  // 768px
  // min 1024px
  @if (map-has-key($font, "size-md")) {
    @media (min-width: $tabletLandscapeViewportWidth) {
      font-size: map-get($font, "size-md");
    }
  }

  // min 1260px
  @if (map-has-key($font, "size-lg")) {
    @media (min-width: $desktopViewportWidth) {
      font-size: map-get($font, "size-lg");
    }
  }

  // min 1520px
  @if (map-has-key($font, "size-xl")) {
    @media (min-width: $desktopLargeViewportWidth) {
      font-size: map-get($font, "size-xl");
    }
  }

  // min 1520px
  @if (map-has-key($font, "size-xxl")) {
    @media (min-width: 1800px) {
      font-size: map-get($font, "size-xxl");
    }
  }
}

// pseudo mixin
@mixin pseudo($display: block, $pos: absolute, $content: " ") {
  content: $content;
  display: $display;
  position: $pos;
}
