button {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  outline:0;
}

.ci-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 44px;
  z-index: 5;

  .ci-link-text {
    @include ci-font((font-weight: $font-base-weight-regular, size-xs: 14px, size-md: 16px));
    text-transform: uppercase;
    max-width: 170px;
    line-height: 1;
    padding-top: 3px;
  }

  &.button-home {
    .icon-border {
      margin: 0 15px;
    }
  }

  &.icon-{
    .icon-border {
      margin-right: 0;
      margin-left: 15px;
    }
  }

  &.icon-before {
    flex-direction: row-reverse;
    justify-content: flex-end;

    .icon {
      margin-right: $spacing-md;
      margin-left: 0;
    }
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border: 2px solid $black;
    border-radius: 50px;
    margin-left: $spacing-sm;

    flex: 0 0 auto;
  }
}

.arrow {
  border: 1px solid $black;
  border-width: 0 0 2px 2px;
  height: 12px;
  margin-left: 0;
  position: relative;
  width: 12px;

  &.right {
    transform: rotate(-135deg);
    left: -3px;
  }

  &.left {
    transform: rotate(45deg);
    left: 3px;
  }

  &.up {
    transform: rotate(135deg);
    top: 3px;
  }

  &.down {
    transform: rotate(-45deg);
    top: -3px;
  }
}

// Button variante auf den Artikelseiten
.ci-button-only-arrow {
  align-items: center;
  border: 1px solid $white;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  position: relative;
  transition: all cubic-bezier(.5, -0.02, .35, 1.2) 300ms;
  width: 44px;
  z-index: 5;
  overflow: hidden;

  .icon {
    align-items: center;
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    position: absolute;
    right: 5px;
  }

  .ci-link-text{
    opacity: 0;
    padding: 0;
    visibility: hidden;
    white-space: nowrap;
  }

  &:hover {
    justify-content: flex-start;
    width: 300px;
    max-width: 100%;

    .ci-link-text {
      color: $white;
      opacity: 1;
      padding: 0 $spacing-md;
      text-transform: uppercase;
      visibility: visible;
      transition: opacity linear 300ms;
      transition-delay: 200ms;
    }
  }

  .arrow {
    border-color: $white;
  }
}


.ci-button {
  .icon-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    border: 1px solid #000;
    border-radius: 50px;
    margin-right: 15px;

    flex: 0 0 auto;
  }

  .icon-home, .icon-arrow {
    height: 25px;
    width: 25px;
    margin-bottom: 0;

    &.right {
      transform: rotate(0deg);
      left: -3px;
    }

    &.left {
      transform: rotate(-180deg);
      left: 3px;
    }

    &.up {
      transform: rotate(-90deg);
      top: 3px;
    }

    &.down {
      transform: rotate(90deg);
      top: -3px;
    }
  }

  &.link-style {
    .ci-link-text {
      color: $white;
      display: inline-block;
      overflow: hidden;
      position: relative;
      text-decoration: none;
      transition: all 1s ease;
      vertical-align: baseline;
      white-space: nowrap;
      z-index: 2;

      &::after {
        background-color: transparent;
        bottom: -2px;
        content: '';
        height: 3px;
        left: 0;
        margin: 0 auto;
        position: absolute;
        right: 0;
        transition: all 300ms ease-in-out;
        width: 3px;
        z-index: -1;
      }

      &:hover {
        color: $white;
        cursor: pointer;
        outline: 0;

        &::after {
          content: '';
          background-color: $white;
          height: 3px;
          outline: 0;
          width: 100%;
        }
      }
    }
  }
}
