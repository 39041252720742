.image-module {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: $spacing-lg;
  max-height: 95vh;
  overflow: hidden;

  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    margin-bottom: $spacing-xxl;
  }
}
