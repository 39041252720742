.teaser-list {
  display: flex;
  flex-wrap: wrap;

  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    flex-wrap: nowrap;
  }
}

.teaser-container {
  display: flex;
  flex-direction: column;
  margin-bottom: $spacing-lg;

  &.half-width {
    @media screen and (min-width: $tabletViewportWidth) {
      max-width: 46%;
    }
  }

  &.w-33 {
    @media screen and (min-width: $tabletViewportWidth) {
      max-width: 46%;
    }
    @media screen and (min-width: $tabletLandscapeViewportWidth) {
      max-width: 30%;
    }
  }

  @media screen and (min-width: $tabletViewportWidth) {
    flex-wrap: wrap;
    margin-right: $spacing-md;
  }

  @media screen and (min-width: $tabletLandscapeViewportWidth) {
    flex-wrap: nowrap;
    width: auto;
  }


  .text-media-headline {
    @include ci-font((font-weight: $font-base-weight-medium, line-height: 1.45em, size-xs: 18px, size-md: 20px, size-lg: 22px)); //laut psd 24px

    @media screen and (min-width: $desktopViewportWidth) {
      margin-bottom: $spacing-md;
    }
  }

  .teaser-text {
    @include ci-font((font-weight: $font-base-weight-light, line-height: 1.25em, size-xs: 16px, size-lg: 18px));

    @media screen and  (min-width: $phoneLandscapeViewportWidth) {
      padding-right: $spacing-md;
    }
  }
}
