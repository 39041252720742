@import url(https://use.typekit.net/gru7kwv.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-family: "benton-sans", Helvetica, sans-serif;
  font-weight: normal;
  color: #fff; }

button {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  outline: 0; }

.ci-button {
  align-items: center;
  cursor: pointer;
  display: flex;
  min-height: 44px;
  z-index: 5; }
  .ci-button .ci-link-text {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    max-width: 170px;
    line-height: 1;
    padding-top: 3px; }
    @media (min-width: 1024px) {
      .ci-button .ci-link-text {
        font-size: 16px; } }
  .ci-button.button-home .icon-border {
    margin: 0 15px; }
  .ci-button.icon- .icon-border {
    margin-right: 0;
    margin-left: 15px; }
  .ci-button.icon-before {
    flex-direction: row-reverse;
    justify-content: flex-end; }
    .ci-button.icon-before .icon {
      margin-right: 25px;
      margin-left: 0; }
  .ci-button .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    border: 2px solid #333;
    border-radius: 50px;
    margin-left: 15px;
    flex: 0 0 auto; }

.arrow {
  border: 1px solid #333;
  border-width: 0 0 2px 2px;
  height: 12px;
  margin-left: 0;
  position: relative;
  width: 12px; }
  .arrow.right {
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
    left: -3px; }
  .arrow.left {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    left: 3px; }
  .arrow.up {
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    top: 3px; }
  .arrow.down {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: -3px; }

.ci-button-only-arrow {
  align-items: center;
  border: 1px solid #fff;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  height: 44px;
  justify-content: center;
  position: relative;
  -webkit-transition: all cubic-bezier(0.5, -0.02, 0.35, 1.2) 300ms;
  transition: all cubic-bezier(0.5, -0.02, 0.35, 1.2) 300ms;
  width: 44px;
  z-index: 5;
  overflow: hidden; }
  .ci-button-only-arrow .icon {
    align-items: center;
    display: flex;
    height: 30px;
    width: 30px;
    justify-content: center;
    position: absolute;
    right: 5px; }
  .ci-button-only-arrow .ci-link-text {
    opacity: 0;
    padding: 0;
    visibility: hidden;
    white-space: nowrap; }
  .ci-button-only-arrow:hover {
    justify-content: flex-start;
    width: 300px;
    max-width: 100%; }
    .ci-button-only-arrow:hover .ci-link-text {
      color: #fff;
      opacity: 1;
      padding: 0 25px;
      text-transform: uppercase;
      visibility: visible;
      -webkit-transition: opacity linear 300ms;
      transition: opacity linear 300ms;
      -webkit-transition-delay: 200ms;
              transition-delay: 200ms; }
  .ci-button-only-arrow .arrow {
    border-color: #fff; }

.ci-button .icon-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border: 1px solid #000;
  border-radius: 50px;
  margin-right: 15px;
  flex: 0 0 auto; }

.ci-button .icon-home, .ci-button .icon-arrow {
  height: 25px;
  width: 25px;
  margin-bottom: 0; }
  .ci-button .icon-home.right, .ci-button .icon-arrow.right {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    left: -3px; }
  .ci-button .icon-home.left, .ci-button .icon-arrow.left {
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    left: 3px; }
  .ci-button .icon-home.up, .ci-button .icon-arrow.up {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    top: 3px; }
  .ci-button .icon-home.down, .ci-button .icon-arrow.down {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    top: -3px; }

.ci-button.link-style .ci-link-text {
  color: #fff;
  display: inline-block;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  vertical-align: baseline;
  white-space: nowrap;
  z-index: 2; }
  .ci-button.link-style .ci-link-text::after {
    background-color: transparent;
    bottom: -2px;
    content: '';
    height: 3px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    -webkit-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    width: 3px;
    z-index: -1; }
  .ci-button.link-style .ci-link-text:hover {
    color: #fff;
    cursor: pointer;
    outline: 0; }
    .ci-button.link-style .ci-link-text:hover::after {
      content: '';
      background-color: #fff;
      height: 3px;
      outline: 0;
      width: 100%; }

.app-euronet .euronet-logo {
  fill: #fff; }

.app-euronet .about-us .ci-link-text {
  color: #fff; }

.app-euronet.is-subpage .euronet-logo {
  fill: #333; }

.app-euronet.is-subpage .about-us {
  border-color: #333; }
  .app-euronet.is-subpage .about-us .ci-link-text {
    color: #333; }

.app-euronet.is-subpage .absolute {
  position: fixed; }

.app-euronet.is-subpage .link-style .ci-link-text {
  color: #333; }
  .app-euronet.is-subpage .link-style .ci-link-text:hover::after {
    background-color: #333; }

.header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  position: absolute;
  width: 100%;
  z-index: 999; }
  @media screen and (min-width: 48em) {
    .header {
      flex-direction: row; } }
  @media screen and (min-width: 78.75em) {
    .header {
      flex-direction: row;
      padding: 30px; } }
  .header a {
    display: block;
    position: relative; }
  .header .euronet-logo {
    height: 45px;
    overflow: visible;
    width: auto; }
    @media screen and (max-width: 48em) {
      .header .euronet-logo {
        height: 30px; } }
  .header .about-us {
    align-items: center;
    border-radius: 50px;
    border: 1px solid #fff;
    color: #fff;
    cursor: pointer;
    display: flex;
    height: auto;
    justify-content: center;
    min-height: 0;
    padding: 0 25px;
    text-transform: uppercase;
    -webkit-transition: background-color ease 250ms;
    transition: background-color ease 250ms; }
    .header .about-us span {
      padding-top: 3px; }
    .header .about-us:hover {
      background-color: rgba(255, 255, 255, 0.2); }

.article-wrapper {
  display: flex;
  flex-direction: row;
  height: 100vh;
  opacity: 0;
  position: absolute;
  width: 100vw; }
  @media screen and (max-width: 767px) {
    .article-wrapper {
      flex-direction: column-reverse;
      opacity: 1;
      position: relative; } }
  .article-wrapper.active {
    opacity: 1; }
  .article-wrapper.odd {
    flex-direction: row-reverse; }
    @media screen and (max-width: 767px) {
      .article-wrapper.odd {
        flex-direction: column-reverse; } }
  .article-wrapper .article-information,
  .article-wrapper .article-image {
    height: 100%;
    overflow: hidden;
    width: 50%;
    z-index: 1; }
    .article-wrapper .article-information .img-holder,
    .article-wrapper .article-image .img-holder {
      background-position: center;
      background-size: cover;
      display: block;
      height: 100%;
      width: 100%; }
    @media screen and (max-width: 767px) {
      .article-wrapper .article-information,
      .article-wrapper .article-image {
        width: 100%; } }
  .article-wrapper .article-information {
    background-color: #96bbc9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px; }
    @media screen and (min-width: 48em) {
      .article-wrapper .article-information {
        padding: 120px 14vw 60px 7vw; } }
    @media screen and (min-width: 1024px) {
      .article-wrapper .article-information {
        padding: 120px 260px 60px 100px; } }
    @media screen and (device-aspect-ratio: 2 / 3) {
      .article-wrapper .article-information {
        justify-content: flex-end;
        padding: 80px; } }
    .article-wrapper .article-information .big-headline-wrapper {
      flex-wrap: wrap;
      max-width: 100%; }
      @media screen and (min-width: 48em) {
        .article-wrapper .article-information .big-headline-wrapper {
          max-width: 77%; } }
      @media screen and (min-width: 78.75em) {
        .article-wrapper .article-information .big-headline-wrapper {
          max-width: 55%; } }
      @media screen and (device-aspect-ratio: 2 / 3) {
        .article-wrapper .article-information .big-headline-wrapper {
          max-width: 100%; } }
      .article-wrapper .article-information .big-headline-wrapper .big-headline {
        font-family: "benton-sans", Helvetica, sans-serif;
        font-weight: normal;
        font-size: 40px;
        text-transform: uppercase;
        line-height: 1.2em;
        font-weight: 100; }
        @media (min-width: 1024px) {
          .article-wrapper .article-information .big-headline-wrapper .big-headline {
            font-size: 80px; } }
        @media (min-width: 78.75em) {
          .article-wrapper .article-information .big-headline-wrapper .big-headline {
            font-size: 95px; } }
        @media screen and (max-width: 48em) {
          .article-wrapper .article-information .big-headline-wrapper .big-headline {
            margin-bottom: 10px; } }
        @media screen and (min-width: 1024px) and (max-height: 700px) {
          .article-wrapper .article-information .big-headline-wrapper .big-headline {
            font-size: 80px; } }
    .article-wrapper .article-information .article-information-headline {
      font-family: "benton-sans", Helvetica, sans-serif;
      font-weight: normal;
      font-size: 18px;
      font-weight: 700; }
      @media (min-width: 1024px) {
        .article-wrapper .article-information .article-information-headline {
          font-size: 22px; } }
    .article-wrapper .article-information .article-subheadline {
      font-family: "benton-sans", Helvetica, sans-serif;
      font-weight: normal;
      font-size: 18px;
      letter-spacing: 0.05em;
      font-weight: 300;
      margin-bottom: 15px; }
      @media (min-width: 78.75em) {
        .article-wrapper .article-information .article-subheadline {
          font-size: 22px; } }
    .article-wrapper .article-information .additional-information {
      flex-direction: column;
      z-index: 5; }
  @media screen and (max-width: 48em) and (max-height: 1024px) {
    .article-wrapper.odd .article-information {
      padding: 80px 40px; } }
  @media screen and (min-width: 769px) {
    .article-wrapper.odd .article-information {
      padding: 120px 30px 60px 140px; } }
  @media screen and (min-width: 1024px) {
    .article-wrapper.odd .article-information {
      padding: 120px 7vw 60px 17vw; } }
  @media screen and (device-aspect-ratio: 2 / 3) {
    .article-wrapper.odd .article-information {
      padding: 80px; } }
  .article-wrapper .article-image {
    cursor: pointer; }

.mobile-datatime {
  display: none; }

@media screen and (max-width: 48em) {
  .mobile-datatime {
    display: block;
    margin-bottom: 10px; } }

.categorie-subpage .header {
  position: fixed;
  z-index: 2; }
  .categorie-subpage .header .about-us {
    border: 2px solid #333;
    color: #333;
    z-index: 2; }

h1, h3, .claim {
  text-transform: uppercase; }

.category__content {
  flex-direction: column;
  display: flex;
  opacity: 1; }
  .category__content .category__content--section {
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
    width: 100%;
    max-width: 1500px;
    padding: 40px 25px 25px 25px;
    position: relative;
    z-index: 4; }
    @media screen and (min-width: 48em) {
      .category__content .category__content--section {
        flex-direction: row;
        padding: 120px 60px 60px 60px; } }
  .category__content .fullwidth-image {
    margin-bottom: 40px; }
    @media screen and (min-width: 1300px) {
      .category__content .fullwidth-image {
        margin-bottom: 100px; } }

.additional-information {
  width: 100%; }

.headline-claim-subpage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 50px; }
  .headline-claim-subpage .intro {
    margin-right: 40px; }
  @media screen and (min-width: 540px) {
    .headline-claim-subpage {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0; } }
  @media screen and (min-width: 48em) {
    .headline-claim-subpage {
      justify-content: start;
      flex-direction: column;
      padding-left: 60px;
      width: 50%; } }
  @media screen and (max-width: 48em) {
    .headline-claim-subpage {
      margin-top: 20px; } }
  .headline-claim-subpage .claim {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    font-weight: 700; }
    @media (min-width: 78.75em) {
      .headline-claim-subpage .claim {
        font-size: 22px; } }
  .headline-claim-subpage h3 {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    font-weight: 500;
    color: #333;
    margin-bottom: 40px; }
    @media (min-width: 78.75em) {
      .headline-claim-subpage h3 {
        font-size: 22px; } }
  .headline-claim-subpage .ci-button {
    -webkit-transition: opacity 250ms ease;
    transition: opacity 250ms ease; }
    .headline-claim-subpage .ci-button:hover {
      opacity: 0.8; }
  .headline-claim-subpage .ci-link-text {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;
    padding: 0; }

.headline-subpage {
  font-family: "benton-sans", Helvetica, sans-serif;
  font-weight: normal;
  font-size: 22px;
  font-weight: 500;
  color: #333;
  margin-bottom: 15px; }
  @media (min-width: 1024px) {
    .headline-subpage {
      font-size: 42px; } }
  @media screen and (min-width: 1024px) {
    .headline-subpage {
      font-weight: 300;
      margin-bottom: 40px; } }
  @media screen and (min-width: 78.75em) {
    .headline-subpage {
      margin-bottom: 60px; } }

.subpage-description {
  font-family: "benton-sans", Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 25px; }
  @media (min-width: 1024px) {
    .subpage-description {
      font-size: 22px; } }
  @media screen and (min-width: 48em) {
    .subpage-description {
      margin-bottom: 0; } }

.parallax-background {
  margin-bottom: 40px;
  position: relative;
  padding: 270px 0 200px 0;
  overflow: hidden;
  width: 100%;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  @media screen and (max-width: 1024px) {
    .parallax-background {
      background-size: auto 100%;
      background-attachment: scroll; } }
  @media screen and (min-width: 1024px) {
    .parallax-background {
      padding-top: 380px; } }

.section-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1500px;
  padding: 25px;
  width: 100%; }
  @media screen and (min-width: 25.875em) {
    .section-wrapper {
      padding: 100px 40px 40px 40px; } }
  @media screen and (min-width: 48em) {
    .section-wrapper {
      padding: 120px 60px 60px 60px; } }
  .section-wrapper .half-width-images {
    margin-bottom: 25px;
    width: 100%; }
    @media screen and (min-width: 25.875em) {
      .section-wrapper .half-width-images {
        margin-bottom: 40px; } }
    @media screen and (min-width: 48em) {
      .section-wrapper .half-width-images {
        margin-bottom: 100px;
        width: 48%; } }

.clock-container {
  display: none;
  left: 50%;
  max-width: 30vw;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  z-index: 5; }
  @media screen and (min-width: 48em) {
    .clock-container {
      display: block; } }
  @media screen and (max-width: 48em) and (max-height: 1024px) {
    .clock-container {
      max-width: 50vw;
      top: 20%;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0); } }

svg.clock .timepoint__hover {
  cursor: pointer;
  fill: #fff;
  opacity: 0;
  stroke-miterlimit: 10;
  stroke: #000; }

svg.clock .timepoint {
  cursor: pointer;
  fill: rgba(255, 255, 255, 0.8);
  -webkit-transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 300ms cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  svg.clock .timepoint.active {
    fill: white; }

svg.clock .timepoint__hover:hover + .timepoint:not(.active), svg.clock .timepoint:not(.active):hover {
  fill: white; }

svg.clock .progress {
  /* clip-path: url(#clipPath); buggy with firefox! */
  fill: none;
  stroke-width: 500;
  stroke: rgba(255, 255, 255, 0.3); }

svg.clock .logo path {
  fill: #fff; }

.text-media-module {
  margin-bottom: 40px; }
  @media screen and (min-width: 1024px) {
    .text-media-module {
      margin-bottom: 100px; } }
  .text-media-module .text-media-container img {
    margin-bottom: 15px; }
    @media screen and (min-width: 1024px) {
      .text-media-module .text-media-container img {
        margin-bottom: 25px; } }

.text-media-container {
  display: flex;
  flex-direction: column;
  justify-content: center; }
  @media screen and (min-width: 48em) {
    .text-media-container {
      flex-direction: row;
      justify-content: space-between; } }
  .text-media-container .text-media-description {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.45em;
    font-weight: 300; }
    @media (min-width: 1024px) {
      .text-media-container .text-media-description {
        font-size: 20px; } }
    @media screen and (min-width: 48em) {
      .text-media-container .text-media-description {
        padding-left: 40px; } }
  @media screen and (min-width: 48em) {
    .text-media-container.image-right {
      flex-direction: row-reverse; } }
  .text-media-container.image-right .text-media-description {
    padding-left: 0; }
    @media screen and (min-width: 48em) {
      .text-media-container.image-right .text-media-description {
        padding-right: 40px; } }
  .text-media-container .w-50 {
    flex-direction: column; }
    @media screen and (min-width: 48em) {
      .text-media-container .w-50 {
        max-width: 50%; } }
  .text-media-container .w-66 {
    flex-direction: column; }
    @media screen and (min-width: 48em) {
      .text-media-container .w-66 {
        align-self: center;
        height: 100%;
        max-width: 46%; } }
    @media screen and (min-width: 1024px) {
      .text-media-container .w-66 {
        max-width: 66%; } }

.text-media-headline {
  font-family: "benton-sans", Helvetica, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 700;
  color: #333;
  margin-bottom: 15px; }
  @media (min-width: 1024px) {
    .text-media-headline {
      font-size: 40px; } }
  @media screen and (min-width: 25.875em) {
    .text-media-headline {
      margin-bottom: 25px; } }
  @media screen and (min-width: 1024px) {
    .text-media-headline {
      margin-bottom: 40px; } }

.text-module {
  margin-bottom: 40px; }
  @media screen and (min-width: 48em) {
    .text-module {
      margin-bottom: 100px; } }
  .text-module.full-width {
    width: 100%; }
    @media screen and (min-width: 48em) {
      .text-module.full-width .text-media-container {
        flex-direction: column;
        justify-content: center; } }
  .text-module.half-width {
    width: 100%; }
    @media screen and (min-width: 48em) {
      .text-module.half-width {
        width: 48%; } }
  .text-module .text-media-headline {
    margin-bottom: 10px; }
    @media screen and (min-width: 48em) {
      .text-module .text-media-headline {
        margin-bottom: 25px; } }

.text-description {
  font-family: "benton-sans", Helvetica, sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 1.45em;
  font-weight: 300; }
  @media (min-width: 1024px) {
    .text-description {
      font-size: 20px; } }
  @media (min-width: 78.75em) {
    .text-description {
      font-size: 22px; } }

.list-item {
  list-style-position: outside;
  list-style: disc;
  margin-bottom: 15px;
  margin-left: 25px; }

.image-module {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  max-height: 95vh;
  overflow: hidden; }
  @media screen and (min-width: 1024px) {
    .image-module {
      margin-bottom: 100px; } }

.teaser-list {
  display: flex;
  flex-wrap: wrap; }
  @media screen and (min-width: 1024px) {
    .teaser-list {
      flex-wrap: nowrap; } }

.teaser-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px; }
  @media screen and (min-width: 48em) {
    .teaser-container.half-width {
      max-width: 46%; } }
  @media screen and (min-width: 48em) {
    .teaser-container.w-33 {
      max-width: 46%; } }
  @media screen and (min-width: 1024px) {
    .teaser-container.w-33 {
      max-width: 30%; } }
  @media screen and (min-width: 48em) {
    .teaser-container {
      flex-wrap: wrap;
      margin-right: 25px; } }
  @media screen and (min-width: 1024px) {
    .teaser-container {
      flex-wrap: nowrap;
      width: auto; } }
  .teaser-container .text-media-headline {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.45em;
    font-weight: 700; }
    @media (min-width: 1024px) {
      .teaser-container .text-media-headline {
        font-size: 20px; } }
    @media (min-width: 78.75em) {
      .teaser-container .text-media-headline {
        font-size: 22px; } }
    @media screen and (min-width: 78.75em) {
      .teaser-container .text-media-headline {
        margin-bottom: 25px; } }
  .teaser-container .teaser-text {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.25em;
    font-weight: 300; }
    @media (min-width: 78.75em) {
      .teaser-container .teaser-text {
        font-size: 18px; } }
    @media screen and (min-width: 25.875em) {
      .teaser-container .teaser-text {
        padding-right: 25px; } }

.panel {
  background: blue;
  display: block;
  height: 400vh;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100vw;
  z-index: 2; }

.panel-2 {
  background: deeppink; }

.footer .euronet-link {
  text-align: center; }
  .footer .euronet-link a {
    align-items: center;
    display: flex;
    justify-content: center; }
    .footer .euronet-link a svg {
      margin-left: 15px; }

.footer .footer-navigation {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1500px;
  padding: 25px;
  width: 90%; }
  @media screen and (max-width: 540px) {
    .footer .footer-navigation {
      flex-direction: column; }
      .footer .footer-navigation .button-home .icon-border {
        margin: 0; } }
  @media screen and (min-width: 48em) {
    .footer .footer-navigation {
      padding: 120px 0 60px 0; } }
  .footer .footer-navigation .ci-button {
    -webkit-transition: opacity 250ms ease;
    transition: opacity 250ms ease; }
    @media screen and (max-width: 540px) {
      .footer .footer-navigation .ci-button:not(:last-of-type) {
        margin-bottom: 25px; } }
    @media screen and (max-width: 540px) {
      .footer .footer-navigation .ci-button.icon- {
        flex-direction: row-reverse;
        justify-content: flex-end; } }
    @media screen and (max-width: 540px) {
      .footer .footer-navigation .ci-button.icon- .icon-border {
        margin-left: 0;
        margin-right: 15px; } }
    .footer .footer-navigation .ci-button:hover {
      opacity: 0.6; }

.footer-next-category {
  display: flex;
  height: 80vh;
  overflow: hidden; }
  @media screen and (max-width: 540px) {
    .footer-next-category {
      flex-direction: column; } }
  .footer-next-category .article-image,
  .footer-next-category .article-information {
    cursor: pointer;
    display: flex;
    height: auto;
    width: 50%; }
    @media screen and (max-width: 540px) {
      .footer-next-category .article-image,
      .footer-next-category .article-information {
        height: 100%;
        width: 100%; } }
    .footer-next-category .article-image .big-headline-wrapper,
    .footer-next-category .article-information .big-headline-wrapper {
      max-width: 350px; }
  .footer-next-category .article-information {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px 20px;
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
    @media screen and (max-width: 48em) and (max-height: 1024px) {
      .footer-next-category .article-information {
        justify-content: flex-end;
        padding: 80px 20px; } }
    @media screen and (min-width: 48em) {
      .footer-next-category .article-information {
        padding: 120px 120px 60px 30px; } }
    @media screen and (min-width: 1024px) {
      .footer-next-category .article-information {
        padding: 120px 260px 60px 100px; } }
    .footer-next-category .article-information .big-headline-wrapper {
      flex-wrap: wrap;
      max-width: 77%; }
      @media screen and (min-width: 78.75em) {
        .footer-next-category .article-information .big-headline-wrapper {
          max-width: 55%; } }
      .footer-next-category .article-information .big-headline-wrapper .big-headline {
        font-family: "benton-sans", Helvetica, sans-serif;
        font-weight: normal;
        font-size: 3.625rem;
        text-transform: uppercase;
        line-height: 1.2em;
        font-weight: 100; }
        @media (min-width: 1024px) {
          .footer-next-category .article-information .big-headline-wrapper .big-headline {
            font-size: 80px; } }
        @media (min-width: 78.75em) {
          .footer-next-category .article-information .big-headline-wrapper .big-headline {
            font-size: 100px; } }
        @media screen and (min-width: 1024px) and (max-height: 700px) {
          .footer-next-category .article-information .big-headline-wrapper .big-headline {
            font-size: 80px; } }
  .footer-next-category .article-image {
    -webkit-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out; }
    .footer-next-category .article-image a {
      display: block;
      height: 100%;
      width: 100%; }
    .footer-next-category .article-image .img-holder {
      background-position: center;
      background-size: cover;
      height: 100%;
      width: 100%; }
  .footer-next-category:hover .article-information {
    width: 55%; }
  .footer-next-category:hover .article-image {
    opacity: 0.8; }

.contact-form fieldset {
  font-family: "benton-sans", Helvetica, sans-serif;
  font-weight: normal;
  font-weight: 500;
  height: 44px;
  margin-bottom: 25px;
  position: relative; }
  @media (min-width: 25.875em) {
    .contact-form fieldset {
      font-size: 16px; } }
  .contact-form fieldset:last-of-type {
    min-height: 100px; }
  .contact-form fieldset .label {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%; }
    @media (min-width: 25.875em) {
      .contact-form fieldset .label {
        font-size: 16px; } }
    .contact-form fieldset .label:before {
      content: " ";
      display: block;
      position: absolute;
      bottom: 0;
      height: 2px;
      -webkit-transition: width 250ms ease-in-out;
      transition: width 250ms ease-in-out;
      width: 0; }
    .contact-form fieldset .label span {
      color: #333;
      left: 5px;
      position: absolute;
      top: 5px;
      -webkit-transition: all 250ms;
      transition: all 250ms;
      z-index: 5; }
  .contact-form fieldset input[type="text"],
  .contact-form fieldset textarea {
    font-family: "benton-sans", Helvetica, sans-serif;
    font-weight: normal;
    font-weight: 500;
    background-color: transparent;
    border-bottom: 2px solid #ddd;
    height: 100% !important;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
    @media (min-width: 25.875em) {
      .contact-form fieldset input[type="text"],
      .contact-form fieldset textarea {
        font-size: 16px; } }
    .contact-form fieldset input[type="text"]:focus ~ .label:before, .contact-form fieldset input[type="text"]:valid ~ .label:before,
    .contact-form fieldset textarea:focus ~ .label:before,
    .contact-form fieldset textarea:valid ~ .label:before {
      background-color: #333;
      width: 100%; }
    .contact-form fieldset input[type="text"]:focus ~ .label span, .contact-form fieldset input[type="text"]:valid ~ .label span,
    .contact-form fieldset textarea:focus ~ .label span,
    .contact-form fieldset textarea:valid ~ .label span {
      font-family: "benton-sans", Helvetica, sans-serif;
      font-weight: normal;
      font-weight: 500;
      color: #333;
      left: 0;
      top: -15px; }
      @media (min-width: 25.875em) {
        .contact-form fieldset input[type="text"]:focus ~ .label span, .contact-form fieldset input[type="text"]:valid ~ .label span,
        .contact-form fieldset textarea:focus ~ .label span,
        .contact-form fieldset textarea:valid ~ .label span {
          font-size: 11px; } }

.contact-form button {
  font-family: "benton-sans", Helvetica, sans-serif;
  font-weight: normal;
  font-weight: 700;
  background-color: #222;
  color: #fff;
  cursor: pointer;
  padding: 10px 40px;
  position: relative;
  text-transform: uppercase;
  z-index: 1; }
  @media (min-width: 25.875em) {
    .contact-form button {
      font-size: 18px; } }
  .contact-form button:before {
    content: " ";
    display: block;
    position: absolute;
    background-color: #444;
    height: 100%;
    left: 0;
    -webkit-transition: width 250ms ease-in-out;
    transition: width 250ms ease-in-out;
    top: 0;
    width: 0;
    z-index: -1; }
  .contact-form button:hover {
    color: #fff; }
    .contact-form button:hover:before {
      width: 100%; }

.contact {
  margin: 0 auto;
  max-width: 800px;
  width: 100%; }

.form-fieldset-headline {
  font-family: "benton-sans", Helvetica, sans-serif;
  font-weight: normal;
  font-weight: 500;
  color: #333;
  margin-bottom: 15px;
  text-transform: inherit; }
  @media (min-width: 25.875em) {
    .form-fieldset-headline {
      font-size: 16px; } }

.form-fieldset {
  display: flex;
  flex-wrap: wrap; }
  .form-fieldset span {
    position: relative;
    top: 4px; }

.form-checkbox-label,
.form-radio-label {
  cursor: pointer;
  display: flex;
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative; }
  .form-checkbox-label:hover .form-radio-button,
  .form-checkbox-label:hover .form-checkbox-button,
  .form-radio-label:hover .form-radio-button,
  .form-radio-label:hover .form-checkbox-button {
    color: #444; }
  .form-checkbox-label input,
  .form-radio-label input {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0; }

.radio-buttons,
.checkbox-buttons {
  height: auto !important;
  margin-bottom: 30px;
  min-height: 60px; }

.form-radio-label {
  margin-right: 25px; }

.form-radio-field:checked ~ .form-radio-button:before {
  color: #333; }

.form-radio-field:checked ~ .form-radio-button:after {
  -webkit-transform: scale(0.5);
          transform: scale(0.5); }

.form-radio-button {
  color: #999;
  cursor: pointer;
  display: block;
  left: 0;
  position: absolute;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .form-radio-button:before, .form-radio-button:after {
    content: " ";
    display: block;
    position: absolute;
    border-radius: 50%;
    border: 0.125rem solid currentColor;
    height: 1rem;
    left: 0;
    margin: 0.25rem;
    top: 0;
    -webkit-transition: color 0.28s ease, -webkit-transform 0.28s ease;
    transition: color 0.28s ease, -webkit-transform 0.28s ease;
    transition: transform 0.28s ease, color 0.28s ease;
    transition: transform 0.28s ease, color 0.28s ease, -webkit-transform 0.28s ease;
    width: 1rem;
    will-change: transform, color; }
  .form-radio-button:after {
    background-color: #333;
    border-color: #333;
    -webkit-transform: scale(0);
            transform: scale(0); }

.checkbox-column {
  margin-right: 60px; }
  .checkbox-column span {
    top: 0; }

.form-checkbox-field:checked ~ .form-checkbox-button {
  color: #333; }
  .form-checkbox-field:checked ~ .form-checkbox-button:before {
    height: 20px;
    opacity: 1;
    -webkit-transition: height 0.28s ease .28s;
    transition: height 0.28s ease .28s; }
  .form-checkbox-field:checked ~ .form-checkbox-button:after {
    height: 10px;
    opacity: 1;
    -webkit-transition: height 0.28s ease;
    transition: height 0.28s ease; }

.form-checkbox-button {
  border-radius: 0.0625rem;
  border: 0.125rem solid currentColor;
  color: #999;
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  -webkit-transition: color 0.28s ease;
  transition: color 0.28s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 20px;
  will-change: color; }
  .form-checkbox-button:before, .form-checkbox-button:after {
    content: " ";
    display: block;
    position: absolute;
    background-color: #333;
    border-radius: 0.25rem;
    height: 0;
    opacity: 0;
    -webkit-transform-origin: left top;
            transform-origin: left top;
    -webkit-transition: opacity 0.28s ease, height 0s linear 0.28s;
    transition: opacity 0.28s ease, height 0s linear 0.28s;
    width: 0.2rem;
    will-change: opacity, height; }
  .form-checkbox-button:before {
    box-shadow: 0 0 0 0.0625rem #fff;
    left: 7px;
    top: 12px;
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }
  .form-checkbox-button:after {
    left: 0;
    top: 0.3rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

.contact-radio-checkbox-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media screen and (min-width: 48em) {
    .contact-radio-checkbox-container {
      flex-direction: row; } }

.contact-form-adress {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px; }
  .contact-form-adress h5 {
    color: #333;
    margin-bottom: 15px; }
  .contact-form-adress span {
    line-height: 20px; }
  .contact-form-adress .contact-form-adress-contact {
    display: flex;
    flex-direction: column;
    margin-top: 20px; }

.clock-display {
  display: flex;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 80%;
  z-index: 15; }
  .clock-display span {
    display: flex;
    width: 100%; }
  .clock-display ul.clock-display__digits {
    height: auto;
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 100%; }
    .clock-display ul.clock-display__digits li {
      font-family: "benton-sans", Helvetica, sans-serif;
      font-weight: normal;
      font-weight: 500;
      color: white;
      font-size: 7.3vw;
      line-height: 1; }

.page-transition {
  background: #fff;
  height: 200vh;
  left: 100%;
  opacity: 1;
  position: fixed;
  top: -50%;
  width: 100vw;
  z-index: 999; }

.preloader-container {
  height: auto;
  left: 50%;
  max-width: 120px;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%; }
  .preloader-container svg .st0 {
    fill: #FFB300; }
  .preloader-container svg .st1 {
    fill: #FB7D28; }
  .preloader-container svg .st2 {
    fill: #CC3300; }

* {
  box-sizing: border-box;
  color: #333;
  margin: 0;
  padding: 0; }

html {
  outline: none; }

h1, h2, h3,
h4, h5, h6 {
  color: #fff;
  text-transform: uppercase; }

a {
  text-decoration: none;
  outline: 0; }

ol, ul, li {
  list-style: none; }

input, fieldset, textarea {
  border: 0; }
  input:focus, input:active, fieldset:focus, fieldset:active, textarea:focus, textarea:active {
    outline: 0; }

img {
  height: auto;
  width: 100%; }

.app-euronet.is-home {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh; }
  @media screen and (max-width: 767px) {
    .app-euronet.is-home {
      overflow: auto;
      -ms-scroll-snap-type: y mandatory;
          scroll-snap-type: y mandatory;
      -webkit-overflow-scrolling: touch; }
      .app-euronet.is-home .header {
        position: fixed; }
      .app-euronet.is-home .article-wrapper {
        scroll-snap-align: start; } }

.app-euronet.is-subpage {
  overflow-x: hidden; }
  .app-euronet.is-subpage img {
    height: 100%; }
  .app-euronet.is-subpage.about-us-page .about-us {
    visibility: hidden; }
  .app-euronet.is-subpage.about-us-page img {
    height: 100%; }

.btn-impress {
  position: fixed;
  right: 25px;
  bottom: 0; }
  .btn-impress .ci-link-text {
    max-width: none; }

