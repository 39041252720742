// import global scss files - for new components scss-files, please import them in components.scss
@import "/frontend/assets/scss/mixins";
@import "/frontend/assets/scss/variables";
@import "/frontend/assets/scss/font";
@import "/frontend/assets/scss/components";

* {
  box-sizing: border-box;
  color: #333;
  margin: 0;
  padding: 0;
}

html {
  outline: none;
}

h1, h2, h3,
h4, h5, h6 {
  color: $white;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  outline: 0;
}

ol, ul, li {
  list-style: none;
}

input, fieldset, textarea {
  border: 0;

  &:focus,
  &:active {
    outline: 0;
  }
}

img {
  height: auto;
  width: 100%;
}

.app-euronet.is-home {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  @media screen and (max-width: 767px) {
    overflow: auto;
    scroll-snap-type: y mandatory;
    -webkit-overflow-scrolling: touch;
    .header {
      position: fixed;
    }

    .article-wrapper {
      scroll-snap-align: start;
    }
  }
}

.app-euronet.is-subpage {
  overflow-x: hidden;

  img {
    height: 100%;
  }

  &.about-us-page {
    .about-us {
      visibility: hidden;
    }

    img {
      height: 100%;
    }
  }
}

.btn-impress {
  position: fixed;
  right: 25px;
  bottom: 0;

  .ci-link-text {
    max-width: none;
  }
}
