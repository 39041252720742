@import "mixins";
@import url("https://use.typekit.net/gru7kwv.css");

// font variables
$ci-font-base:  "benton-sans", Helvetica, sans-serif;
$ci-font-headline: "benton-sans", "Times New Roman", serif;
//Todo: Black Display font gibet nisch in dem Projekt

$font-size-base: 14px;
$font-size-h1: 55px;
$font-size-h2: 60px;
$font-size-h3: 50px;
$font-size-h4: 40px;
$font-size-h5: 30px;
$font-size-h6: 20px;

$font-letter-spacing: 0.01em;
$font-headline-letter-spacing: 0.02em;

$btn-font-size: 14px;
$btn-icon-size: 14px;

$font-base-weight-extra-thin: 100;
$font-base-weight-thin: 200;
$font-base-weight-light: 300;
$font-base-weight-regular: 500;
$font-base-weight-medium: 700;

$font-color-base: #333;

h1 {
  @include ci-font();
  color: $white;

}
