.panel {
  background: blue;
  display: block;
  height: 400vh;
  left: 0;
  position: absolute;
  top: 50%;
  width: 100vw;
  z-index: 2;
}

.panel-2 {
  background: deeppink;
}
